<template>
    <section class="flex flex-col gap-4">
        <div v-if="notifications && notifications.length && !isBusy" class="flex flex-col gap-4">
            <MyAccountLegend :help-link="helpLink">
                {{ $t("MyAccount.MyNotificationsTitle") }}
            </MyAccountLegend>

            <p>{{ $t("MyAccount.NotificationsDescription") }}</p>

            <slot />
            <div class="flex justify-start items-center">
                <Button variant="primary" @click="bulkDelete()">
                    {{ $t("MyAccount.DeleteAlerts") }}
                </Button>
            </div>

            <div v-show="showError" class="alert alert-danger">
                {{ $t("MyAccount.BulkUpdateError") }}
            </div>

            <div class="table-scroll">
                <table class="table table-striped">
                    <thead>
                        <tr class="text-left">
                            <th />
                            <th>
                                {{ $t("Global.Manufacturer") }}
                            </th>
                            <th>
                                {{ $t("MyAccount.Part") }}
                            </th>
                            <th>
                                {{ $t("MyAccount.StockAlerts") }}
                            </th>
                            <th>
                                {{ $t("MyAccount.PriceAlerts") }}
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="notification in notifications" :key="notification.Id">
                            <td class="text-center no-border">
                                <input v-model="selectedIds[notification.Id]" type="checkbox" />
                            </td>
                            <td>{{ notification.Part.Manufacturer.Name }}</td>
                            <td>
                                <a :href="notification.Part.PartUrl">
                                    {{ notification.Part.PartNumber }}
                                </a>
                            </td>
                            <td>
                                <div v-for="alert in notification.StockAlerts" :key="alert.Id">
                                    <div v-if="alert.Status == ALERT_STATUSES.Enabled" class="flex gap-2 items-center">
                                        <ModifierBadge :modifier="alert.Modifier" class="py-1 px-2" />
                                        <span>{{ alert.Quantity.toLocaleString() }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div v-for="alert in notification.PriceAlerts" :key="alert.Id">
                                    <div v-if="alert.Status == ALERT_STATUSES.Enabled" class="flex gap-2 items-center">
                                        <ModifierBadge :modifier="alert.Modifier" class="py-1 px-2" />
                                        <span>{{ alert.Amount }}% for {{ alert.Quantity.toLocaleString() }}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="flex gap-2 justify-end">
                                <Button
                                    variant="primary"
                                    class="rounded-full"
                                    :title="$t('Global.Edit')"
                                    @click="openNotification(notification)">
                                    {{ $t("Global.Edit") }}
                                </Button>
                                <Button
                                    variant="danger"
                                    class="rounded-full"
                                    :title="$t('Global.Delete')"
                                    @click="deleteAlerts(notification.Id)">
                                    {{ $t("Global.Delete") }}
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Dialog v-model:open="alertModal">
                <DialogContent id="alertModal">
                    <DialogTitle>
                        {{ $t("MyAccount.Alert") }}
                    </DialogTitle>
                    {{ $t("MyAccount.PleaseSelect") }}
                    <DialogFooter>
                        <Button variant="primary" fill="solid" @click="alertModal = false">
                            {{ $t("Global.Ok") }}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            <Dialog v-model:open="notificationConfirmModal">
                <DialogContent id="notificationConfirmModal">
                    <DialogTitle>
                        {{ $t("MyAccount.PleaseConfirm") }}
                    </DialogTitle>
                    {{ confirmModalMessage }}
                    <DialogFooter>
                        <Button variant="default" @click="notificationConfirmModal = false">
                            {{ $t("Global.Cancel") }}
                        </Button>
                        <Button variant="primary" fill="solid" @click="bulkFunc && bulkFunc()">
                            {{ $t("Global.Ok") }}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
        <div v-else-if="isBusy">
            <div class="text-center">
                <Spinner />
                <br />
                <p>{{ $t("MyAccount.BusyIndicator") }}</p>
            </div>
        </div>
        <div v-else>
            <p class="text-center">
                {{ $t("MyAccount.NoNotifications") }}
            </p>
        </div>
        <NotificationDialog ref="notificationDialog" @close="closeModal" @update="updateNotification" />
    </section>
</template>

<script setup lang="ts">
import type { AlertStatus } from "~/utils/constants";

const { t } = useI18n();
const { gtag } = useGtag();
const api = useApi();

const notificationConfirmModal = ref(false);
const alertModal = ref(false);
const showError = ref(false);
const confirmModalMessage = ref("");
const bulkFunc = ref<(() => void) | null>(null);
const isSaving = ref(false);
const ALERT_STATUSES = {
    Enabled: 1,
    Deleted: 3,
};
const selectedIds = ref<Record<number, boolean>>({});
const notificationDialog = useTemplateRef("notificationDialog");

const helpLink = computed(() => {
    return PageHelpers.GetDocsUrl("my-account/");
});

const {
    data: notifications,
    refresh: getNotifications,
    status: notificationStatus,
} = await useFetchApi<PartNotification[]>("api/part-notifications");
const isBusy = computed(() => notificationStatus.value == "pending" || isSaving.value);

function openNotification(notification: PartNotification) {
    notificationDialog.value?.openDialog(notification.Part, notification);
}

function closeModal() {
    alertModal.value = false;
    notificationConfirmModal.value = false;
}

function deleteAlerts(Id: number) {
    gtag("event", "my_alerts_delete");

    confirmModalMessage.value = t("MyAccount.DeleteNotificationConfirmation");
    bulkFunc.value = () => {
        bulkSave([Id], ALERT_STATUSES.Deleted);
        notificationConfirmModal.value = false;
    };
    notificationConfirmModal.value = true;
}

function bulkDelete() {
    gtag("event", "my_alerts_bulk_delete");

    const ids: number[] = Object.entries(selectedIds.value)
        .filter((e) => e[1])
        .map((e) => parseInt(e[0]));

    if (ids.length) {
        confirmModalMessage.value = t("MyAccount.DeleteNotificationsConfirmation");
        bulkFunc.value = () => {
            bulkSave(ids, ALERT_STATUSES.Deleted);
            notificationConfirmModal.value = false;
        };
        notificationConfirmModal.value = true;
    } else {
        alertModal.value = true;
    }
}

async function bulkSave(ids: number[], status: AlertStatus) {
    isSaving.value = true;

    try {
        const response = await api<boolean>("/api/part-notifications/bulk", {
            method: "PUT",
            body: {
                Ids: ids,
                Status: status,
            },
        });

        if (response) {
            showError.value = false;
            getNotifications();
        }
    } catch (_error) {
        showError.value = true;
    } finally {
        isSaving.value = false;
    }
}

function updateNotification(record: PartNotification) {
    if (!notifications.value) return;

    if (record.StockAlerts.length === 0 && record.PriceAlerts.length === 0) {
        notifications.value = notifications.value.filter((n) => n.Id !== record.Id);
    } else {
        const index = notifications.value.findIndex((n) => n.Id === record.Id);

        if (index > -1) {
            notifications.value[index].StockAlerts = record.StockAlerts;
            notifications.value[index].PriceAlerts = record.PriceAlerts;
        }
    }

    getNotifications();
}
</script>
